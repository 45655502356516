import React from 'react'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronsLeft } from "@fortawesome/pro-duotone-svg-icons";

const ContactFormSuccess = (props) => {
    const { title, subTitle, img } = props;
    return (
        <section className="contact-area pb-100 pt-100">
            <div className="container">
                <div className="section-title">
                    <h2>{title}</h2>
                    <p>{subTitle}</p>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="contact-image">
                            <img src={img} alt="contact" style={{maxHeight: '400px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactFormSuccess
