import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO'
import NavbarDynamic from "../../components/App/NavbarDynamic"
import PageBanner from '../../components/Common/PageBanner'
import FooterMinimal from "../../components/App/FooterMinimal"
import ContactFormSuccess from '../../components/Contact/ContactFormSuccess'
import { bannerSuccess, pageHeader } from '../../data/page-data/contactData';

const headerInfo = {
    title: 'Contact Success | BryanLokey.com',
    description: 'The contact success page',
    keywords: 'contact success',
    canonical: 'https://bryanlokey.com/contact/success/',
    ogImage: 'TBD',
};

const ContactSuccess = () => {
    return (
        <div>
          <SEO {...headerInfo}/>
          <Layout>
            <NavbarDynamic />
            <PageBanner {...pageHeader}/>
            <ContactFormSuccess {...bannerSuccess} />
            <FooterMinimal />
          </Layout>
        </div>
    );
}

export default ContactSuccess
